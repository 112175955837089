import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import useToken from '../../hooks/useToken';
import { EditIcon, SearchIcon } from '../../components/Icons';
import config from '../../config';
import { fetchWithTokenFromAPI2 } from '../../fetcher';

const GET_PAYROLL_CYCLES = gql`
  query GetPayrollCycles {
    getPayrollCycles {
      id
      week_start
      week_end
    }
  }
`;

export default function PerDiem() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { isTokenExpired, deleteToken, getUserId, hasPermission } = useToken();

  const [filteredData, setFilteredData] = useState([]);

  const [usersToFilter, setUsersToFilter] = useState([]);
  const [usersFilter, setUsersFilter] = useState();

  const [payrollCycleFilter, setPayrollCycleFilter] = useState();
  const [currentPayrollCycleIndex, setCurrentPayrollCycleIndex] =
    useState(null);

  const { loading: payrollCyclesLoading, data: payrollCyclesData } =
    useQuery(GET_PAYROLL_CYCLES);

  if (isTokenExpired()) {
    deleteToken();
  }

  useEffect(() => {
    if (payrollCyclesData !== undefined) {
      // Calculate the page we need to start on to put today in view
      let today = DateTime.local();

      // Get start of day
      let localToday = DateTime.fromObject({
        year: today.year,
        month: today.month,
        day: today.day,
      });

      let payrollIndex = payrollCyclesData.getPayrollCycles.findIndex(
        (x) => x.week_start >= localToday.ts
      );

      setCurrentPayrollCycleIndex(payrollIndex);
      setPayrollCycleFilter(payrollIndex);
    }
  }, [payrollCyclesData]);

  useEffect(() => {
    fetchWithTokenFromAPI2(
      `/perdiem`,
      {
        method: 'GET',
      },
      true,
    ).then(response => {
      setData(response.map((x) => {
        let userId = getUserId();

        // Append edit visibility
        let isEditVisible = false;

        if (hasPermission('isadmin')) {
          isEditVisible = true;
        }

        if (x.payrollCycle.current) {
          isEditVisible = true;
        }

        return {
          isEditVisible,
          ...x,
        };
      }));

      setLoading(false);
    });
  }, []);

  useEffect(() => {
    // Users
    // Get User to Filter by
    let uniqueUsers = [
      ...new Set(data.map((x) => x.driver.id)),
    ].map((x) => {
      // Get full record
      let record = data.filter((y) => y.driver.id === x)[0];

      return {
        id: x,
        name: `${record.driver.firstName} ${record.driver.lastName}`,
      };
    });

    setUsersToFilter(uniqueUsers.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }));

    let filteredRecords = data.filter((x) => {
      let showRecord = true;

      // Users
      if (usersFilter) {
        if (x.driver.id != usersFilter) {
          showRecord = false;
        }
      }

      // Payroll Cycle
      if (payrollCycleFilter) {
        if (x.payrollCycle.id != payrollCycleFilter) {
          showRecord = false;
        }
      }

      return showRecord;
    });

    setFilteredData(filteredRecords);
  }, [
    data,
    usersFilter,
    payrollCycleFilter
  ]);

  let loadingElement = (
    <h4 style={{ padding: '1em', marginBottom: '0px' }}>
      Loading<span className="animated-dots"></span>
    </h4>
  );

  if (loading || payrollCyclesLoading) return loadingElement;

  // if (loading) return <h3>Loading...</h3>;

  function toCurrency(numberString) {
    let number = parseFloat(numberString);

    let response = 'Not available';

    if (!isNaN(number)) {
      response = '$' + number.toLocaleString('USD');
    }

    return response;
  }

  const columns = [
    {
      name: 'Id',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Driver',
      selector: (row) => `${row.driver.firstName} ${row.driver.lastName}`,
      sortable: true,
      width: '150px',
    },
    {
      name: 'Expense From',
      selector: (row) => row.payrollCycle.weekStartFormatted,
      sortable: true,
      width: '150px',
    },
    {
      name: 'Expense To',
      selector: (row) => row.payrollCycle.weekEndFormatted,
      sortable: true,
      width: '150px',
    },
    {
      name: 'Days Worked',
      selector: (row) => row.daysWorked,
      sortable: true,
    },
    {
      name: 'Rate',
      selector: (row) => row.perDiemRate,
      sortable: true,
      format: (row) => toCurrency(row.perDiemRate),
    },
    {
      name: 'Total Per Diem',
      selector: (row) => row.totalPerDiem,
      sortable: true,
      format: (row) => toCurrency(row.totalPerDiem),
    },
    {
      name: 'Submitted',
      selector: (row) => row.createdDate,
      sortable: true,
      format: (row, index) => {
        const estDate = DateTime.fromISO(row.createdDate, { zone: 'utc' }).setZone('America/New_York').toFormat('MM/dd/yyyy HH:mm:ss');

        return estDate;
      },
      width: '150px',
    },
    {
      name: 'Actions',
      cell: (row) => {
        return (
          <>
            {row.isEditVisible && (
              <Link to={`/perdiem/${row.id}`} className="btn btn-link">
                <EditIcon /> Edit
              </Link>
            )}
          </>
        );
      },
    },
  ];

  let payrollCycles = payrollCyclesData.getPayrollCycles.map((x) => {
    let weekStart = DateTime.fromMillis(parseInt(x.week_start), {
      zone: 'utc',
    }).toFormat('MM/dd/yyyy');

    let weekEnd = DateTime.fromMillis(parseInt(x.week_end), {
      zone: 'utc',
    }).toFormat('MM/dd/yyyy');

    return (
      <option key={x.id} value={x.id}>
        {weekStart} - {weekEnd}
      </option>
    );
  });

  const handleFilterChange = (e) => {
    switch (e.target.name) {
      case 'user':
        let parsedUser = parseInt(e.target.value);

        if (!isNaN(parsedUser)) {
          setUsersFilter(parsedUser);
        } else {
          setUsersFilter(undefined);
        }
        break;
      case 'payrollcycle':
        setPayrollCycleFilter(parseInt(e.target.value));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="row">

        {/* Driver */}
        <div className="form-group col-md-3 mb-3">
          <label className="form-label">Driver</label>

          <select
            name="user"
            className="form-select"
            type="text"
            onChange={handleFilterChange}
          >
            <option value="">Select</option>

            {usersToFilter.map((x) => {
              return (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              );
            })}
          </select>
        </div>

        {/* Payroll Cycle */}
        <div className="form-group col-md-4 mb-3">
          <label className="form-label">Payroll Cycle</label>

          <select
            name="payrollcycle"
            className="form-select"
            type="text"
            onChange={handleFilterChange}
            defaultValue={currentPayrollCycleIndex}
          >
            <option value="">Select</option>

            {/* Pull in all other Payroll Cycles */}
            {payrollCycles}
          </select>
        </div>
      </div>
      <div className="card mt-2 mb-1">
        <DataTable data={filteredData} columns={columns} pagination striped />
      </div>
    </>
  );
}
